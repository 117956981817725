<template>
	<div class="card card_login animation_fadeFlyInLeft">
		<div class="card_header card_header_login">
			<h1>Login</h1>
		</div>
		<div class="card_content card_content_login">
			<input type="text" name="username" v-model="username" placeholder="Username" />
			<input type="password" name="password" v-on:keyup.enter="login()" v-model="password" placeholder="Password" />
			<button class="button_submit button_login" v-on:click="login()">Sign in</button>
			<p class="back_login"><a href="upload">Back to Main Page</a></p>
		</div>
	</div>
</template>

<script>
	import Api from '@services/Api'
	import Localize from '@localize'
	import { EventBus, Names } from '@/EventBus'

	export default {
		data() {
			return {
				username: "",
				password: ""
			}
		},
		mounted() {
			EventBus.$on(Names.LoginError, function (error) {
				console.error(error)
				this.notify(error)
			}.bind(this))
		},
		methods: {
			async login() {
				try {
					await Api.serviceInfo()
					let formData = new FormData()
					formData.append('userName', this.username)
					formData.append('password', this.password)

					let response = await Api.login(formData)
					localStorage.token = response.data.token
					localStorage.expirationTime = response.data.expiration
					this.$router.push({ name: 'admin' });
				}
				catch { }
			},
			returnToHomePage() {
				this.$router.push({ name: 'upload' });
			},
			notify(response) {
				if (response != null && response.status == 401) {
					EventBus.$emit(Names.ShowError, Localize.login().loginUnauthorized)
				}
				else if (response != null && response.status == 403) {
					EventBus.$emit(Names.ShowError, Localize.login().loginForbid)
				}
				else {
					EventBus.$emit(Names.ShowError, Localize.uploadView().message.somethingWentWrong)
				}
			}
		}
	}
</script>

<style>
.card_login {
	width: 320px;
	padding: 20px;
}

.card_header_login {
    width: 100%;
    line-height: inherit;
    color: #666;
    border-bottom: 1px solid #e5e5e5;
}

.card_header_login h1 {
	margin: 0 auto;
}
	
.card_content_login {
	display: block;
}

.card_content_login input {
	display: block;
	font-size: 16px;
	padding: 6px 12px;
	border: 1px solid #ccc;
    border-radius: 4px;
	margin: 0px 0px 10px;
	width: 280px;
	align-content:center;
}

.card_content_login button {
	display: block;
	color: white;
	border: 1px solid rgb(255, 255, 255);
	border-radius: 4px;
	font-size: 20px;
	height: 34px;
	margin: 0;
	padding: 0;
}

.button_login {
	width: 100px;
	background-color: rgb(0, 121, 52);
    color: rgb(243, 250, 253);
    border-color: rgb(0, 121, 52);
	display: inline-block;
	float:left;
}

.button_login:hover:not([disabled]){
	background-color:rgb(63, 156, 53);
	border-color: rgb(63, 156, 53);
}

.back_login {
	float:right;
	padding: 7px 5px;
	opacity: 0.45;
}
</style>
